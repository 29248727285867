.f-r {
  display: flex;
  flex-direction: row;
}

.f-c-c {
  align-items: center;
  justify-content: center;
}

.f-c-e {
  align-items: center;
  justify-content: flex-end;
}

.f-c-b {
  align-items: center;
  justify-content: space-between;
}

.f-c-s {
  align-items: center;
  justify-content: flex-start;
}

.f-c-a {
  align-items: center;
  justify-content: space-around;
}

.f-s-a {
  align-items: flex-start;
  justify-content: space-around;
}

.f-c {
  display: flex;
  flex-direction: column;
}

.f-s-b {
  justify-content: space-between;
  align-items: start;
}