:root {
  --color-primary: #1677ff;
  --color-primary-light: #5a7bff;
  --color-warning: #FF5733;
  --color-text: #001420;
  --color-text-light: #93989A;
  --color-border: 1px solid #EBEBEB;
  --font-max: 18px;
  --font-base: 16px;
  --font-small: 14px;
  --font-mini: 12px;
  --bg-primary: rgb(90 123 255 / 10%);
  --bg-grey: rgb(0 20 32 / 10%);
}



.f-max {
  font-size: var(--font-max);
}

.f-b {
  font-size: var(--font-base);
}

.f-s {
  font-size: var(--font-small);
}

.f-m {
  font-size: var(--font-mini);
}

.primary {
  color: var(--color-primary);
}

.primary-light {
  color: var(--color-primary-light);
}

.warning {
  color: var(--color-warning);
}

.label {
  color: var(--color-text-light);
}

.img-c-style {
  width: 100%;
  height: 100%;
  border: none;
  display: block;
  object-fit: cover;
  object-position: center center;
}

.ell-text {
  width: 100%;
  max-width: 100%;
  /* 设置元素的宽度 */
  white-space: nowrap;
  /* 禁止换行 */
  overflow: hidden;
  /* 隐藏溢出的内容 */
  text-overflow: ellipsis;
  /* 使用省略号代替溢出的内容 */
}

.m-h-10 {
  margin: 10px 0;
}

.m-h-8 {
  margin: 8px 0;
}

.m-l-10 {
  margin-left: 10px;
}

.operaties {
  cursor: pointer;
}

.h-s {
  font-family: 'HarmonyOS_Sans';
}

.h-s-m {
  font-family: 'HarmonyOS_Sans_Medium';
}

.h-s-l {
  font-family: 'HarmonyOS_Sans_Light';
}

.h-s-b {
  font-family: 'HarmonyOS_Sans_Black';
}

.h-s-bo {
  font-family: 'HarmonyOS_Sans_Bold';
}


.o-m {
  font-family: OPPOSans-M;
}

.o-r {
  font-family: OPPOSans-R;
}

.o-b {
  font-family: OPPOSans-B;
}

.o-h {
  font-family: OPPOSans-H;
}

.o-l {
  font-family: OPPOSans-L;
}