@font-face {
  font-family: 'HarmonyOS_Sans_Black';
  src: url('./HarmonyOS_Sans_Black.ttf');
  /* 注意这里一定要是英文字母，服务器不识别中文 */
}

@font-face {
  font-family: 'HarmonyOS_Sans_Bold';
  src: url('./HarmonyOS_Sans_Bold.ttf');
  /* 注意这里一定要是英文字母，服务器不识别中文 */
}

@font-face {
  font-family: 'HarmonyOS_Sans_Light';
  src: url('./HarmonyOS_Sans_Light.ttf');
  /* 注意这里一定要是英文字母，服务器不识别中文 */
}

@font-face {
  font-family: 'HarmonyOS_Sans_Medium';
  src: url('./HarmonyOS_Sans_Medium.ttf');
  /* 注意这里一定要是英文字母，服务器不识别中文 */
}

@font-face {
  font-family: 'HarmonyOS_Sans';
  src: url('./HarmonyOS_Sans_Regular.ttf');
  /* 注意这里一定要是英文字母，服务器不识别中文 */
}