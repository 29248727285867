.global-button {
    width: 160px;
    height: 48px;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    border: none;
}

.global-button-default {

    color: #001420;

    background: #EEEEEE;

}

.global-button-primary {
    color: #FFFFFF;
    background: var(--color-primary-light);
}


.global-color-black {
    color: #000000
}

.global-cursor-pointer {
    cursor: pointer;
}

.global-confirm-submit-button {
    border: none;
    width: 120px;
    height: 48px;
    border-radius: 12px;
}

.global-confirm-disabled-button {

    background: var(--color-primary-light);
    opacity: 0.3;
    color: #FFFFFF;
    cursor: not-allowed;
}


.global-primary-color {
    color: var(--color-primary);
}